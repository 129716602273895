<template>
    <div>
        <section class="login">
            <div class="half_inner height_half_inner">
                <header class="section_header header_flex">
                    <h2 class="text_xxl text_white text_300">Contacto</h2>
                    <div class="breadcrumbs">
                        <ul>
                            <li>
                                <router-link to="/" class="breadcrumbs_item">Home<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg></router-link>
                            </li>
                            <li>
                                <span class="breadcrumbs_item">Contacto</span>
                            </li>
                        </ul>
                    </div>
                </header>
                <div class="content_block padded rounded white">
                    <div class="grid grid_pad grid_wrap flex_space">
                        <div class="grid_col grid_col_1_1">
                            <div class="grid_col_box mb30">
                                <h2 class="text_xl mb30">Aquí es donde puedes ponerte en contacto con nosotros.</h2>
                            </div>
                        </div>
                                
                        <div class="grid_col grid_col_1_2">
                            <div class="grid_col_box">

                                <h2 class="text_l text_grey medium_border mb20">Por mensaje</h2>
                                <form class="form" @submit.prevent="send">
                                    <div class="input_block">
                                        <input type="text" placeholder="Nombre" v-model="name"/>
                                    </div>
                                    <div class="input_block">
                                        <input type="email" placeholder="Email" v-model="email"/>
                                    </div>
                                    <div class="input_block">
                                        <textarea placeholder="Mensaje" v-model="mensaje"></textarea>
                                    </div>
                                    <div class="input_block">
                                        <button type="text">Enviar</button>
                                    </div>
                                    <div class="input_block">
                                        <div class="legal_line">
                                            <p class="legal">Todos los campos son imprescindibles</p>
                                        </div>
                                    </div>
                                    <div class="input_block" v-if="msg">
                                        <div class="message" :class="msg.type">
                                            <h3>{{msg.text}}</h3>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                        <div class="grid_col grid_col_1_2">
                            <div class="grid_col_box mb30">
                                <h2 class="text_l text_grey medium_border mb20">Por teléfono o mail</h2>
                                <a href="mailto:ventas@shopohs.es" class="contact_way">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z"/></svg>ventas@shopohs.es
                                </a>
                                <div class="contact_way">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 2c0-1.104-.896-2-2-2h-10c-1.104 0-2 .896-2 2v20c0 1.104.896 2 2 2h10c1.104 0 2-.896 2-2v-20zm-8.5 0h3c.276 0 .5.224.5.5s-.224.5-.5.5h-3c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm1.5 20c-.553 0-1-.448-1-1s.447-1 1-1c.552 0 .999.448.999 1s-.447 1-.999 1zm5-3h-10v-14.024h10v14.024z"/></svg>660 652 838
                                </div>
                                <h4>
                                    Horario de atención:
                                    <b style="display: block;">De lunes a jueves, de 9-14 y de 16 a 18.30 horas</b>
                                    <b style="display: block;">Viernes, de 9-15 horas</b>
                                </h4>
                            </div>
                        </div>
 
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Contacto',
    data: function(){
        return{
            name: null,
            email: null,
            mensaje: null,
            msg: null
        }
    },
    methods: {
        send: function(){
            if(!this.name || this.name.trim() == ''){
                this.msg = {
                    type: 'ko',
                    text: 'Falta el nombre'
                }
                return;
            }
            else if(!this.email || this.email.trim() == ''){
                this.msg = {
                    type: 'ko',
                    text: 'Falta el email'
                }
                return;
            }
            else if(!this.mensaje || this.mensaje.trim() == ''){
                this.msg = {
                    type: 'ko',
                    text: 'Falta el mensaje'
                }
                return;
            }
            this.msg = null;

            axios
                .post(process.env.VUE_APP_URL+'contact', {
                    name: this.name,
                    email: this.email,
                    message: this.mensaje
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.name = this.email = this.mensaje = null;
                        this.msg = {
                            type: 'ok',
                            text: 'Enviado correctamente'
                        }
                    }
                    else{
                        this.msg = {
                            type: 'ko',
                            text: rs.msg
                        }
                    }
                })
                .catch(error => this.$parent.error = 'Hubo un error de conexion. intentelo mas tarde o contacte con administracion')
        }
    }
}
</script>